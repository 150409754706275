<docs>
# 用于文件预览
</docs>

<template>
  <div>
    <!-- <TopWrapper
      :topObj="{
        bgColor: '#fff',
        title: '文件预览',
        txtColor: '#333',
        isBack: true,
        isMsg: false,
      }"
    /> -->
    <fileReader :url="url" :fileName="fileName" />
  </div>
</template>

<script>
import fileReader from "@/components/fileReader";
import TopWrapper from "@/components/topWrapper/index.vue";
export default {
  name: "fileScan",
  inheritAttrs: false,
  props: {},
  computed: {
    url() {
      return this.$route.query.url;
    },
    fileName() {
      return this.$route.query.fileName;
    },
  },
  components: { fileReader ,TopWrapper },
  data() {
    return {};
  },
  watch: {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
