<docs>
# 用于文件预览
</docs>

<template>
  <div v-if="!loading" class="file-box">
    <img :src="decoUrl" v-if="imgTypes.indexOf(suffixName) > -1" class="img" />
    <video
      v-else-if="videoTypes.indexOf(suffixName) > -1"
      autoplay="autoplay"
      muted="muted"
      loop="loop"
    >
      <source :src="decoUrl" type="video/mp4" />
      <source :src="decoUrl" type="video/ogg" />
      很抱歉 , 当前浏览器不支持现有视频格式 ~
    </video>
    <audio controls="controls" v-else-if="autoTpyes.indexOf(suffixName) > -1">
      <source :src="decoUrl" type="audio/mpeg" />
      <source :src="decoUrl" type="audio/ogg" />
      <source :src="decoUrl" type="audio/wav" />
      很抱歉 , 当前浏览器不支持现有音频格式 ~
    </audio>
    <iframe
      :src="decoUrl"
      frameborder="0"
      v-else-if="readTypes.indexOf(suffixName) > -1"
      class="file"
    ></iframe>
    <iframe
      :src="htmlUrl"
      frameborder="0"
      v-else-if="htmlUrl"
      class="file"
    ></iframe>
    <div v-else>文件格式暂不支持预览</div>
  </div>
</template>

<script>
import { fileUrlToHtml } from "@/api/cdn";

export default {
  name: "fileReader",
  inheritAttrs: false,

  props: {
    url: String,
    fileName: String,
  },
  computed: {
    suffixName() {
      return this.fileName.replace(/.*\./g, "").toLowerCase();
    },
    decoUrl() {
      return decodeURIComponent(this.url);
    },
  },
  data() {
    return {
      htmlUrl: "",
      loading: false,
      imgTypes: ["png", "jpeg", "bmp", "tiff", "jpg"],
      readTypes: ["pdf", "html"], //浏览器直接阅读格式
      transTypes: [
        "doc",
        "docx",
        "wps",
        "wpt",
        "xls",
        "xlsx",
        "et",
        "xlsm",
        "csv",
        "ppt",
        "pptx",
        "sql",
        "txt",
      ], //浏览器不能直接阅读，需要转换的格式
      videoTypes: ["mp4", "webm", "ogg"],
      autoTpyes: ["mp3", "ogg", "wav"],
    };
  },
  watch: {},
  mounted() {
    this.init();
  },
  methods: {
    init() {
      let types = this.transTypes;
      if (-1 == types.indexOf(this.suffixName)) {
        return;
      }
      this.loading = true;
      fileUrlToHtml({
        fileUrl: this.decoUrl,
      }).then((res) => {
        this.htmlUrl = res.data;
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.file-box {
  width: 7.5rem;
  height: 100%;
  .img {
    width: 7.5rem;
  }
  .file {
    width: 7.5rem;
    height: 100%;
  }
}
</style>
